<template>
  <div class="bg-white">

    <div class="block block-rounded text-center" id="ctproduct">
      <div class="block-content">
        <h2>SuperDTU系列云盒子</h2>
        <p>
          <a
            target="_blank"
            class="mr-20"
            href="https://item.taobao.com/item.htm?spm=a213gs.success.result.1.6e4e7a8658QZZR&id=644952953434"
            >1）398元SuperDTU 淘宝直接采购链接</a
          >
          <a
            target="_blank"
            href="https://oss.cnwscada.com/officalWebsite/assets/download/down/dtubook.pdf"
            >2）SuperDTU系列云盒子产品手册</a
          >
          ,如需下载，请进入“下载中心”。
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-12" id="index">
        <div
          class="block block-rounded block-bordered block-mode-loading-refresh"
        >
          <img
            src="https://oss.cnwscada.com/officalWebsite/assets/image/dtuproduct.png"
            style="height: 100%; width: 100%"
          />
        </div>
      </div>
    </div>

    <div class="block block-rounded text-center" id="ctproduct">
      <div class="block-content">
        <h2>CT系列云屏</h2>
        <p>
          <a
            target="_blank"
            href="https://oss.cnwscada.com/officalWebsite/assets/download/link/cttouchbook.pdf"
            >1）CT系列云屏产品手册</a
          >
          ,如需下载，请进入“下载中心”。
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-12" id="index">
        <div
          class="block block-rounded block-bordered block-mode-loading-refresh"
        >
          <img
            src="https://oss.cnwscada.com/officalWebsite/assets/image/ctproduct.png"
            style="height: 100%; width: 100%"
          />
        </div>
      </div>
    </div>

    <div class="block block-rounded text-center" id="cblproduct">
      <div class="block-content">
        <h2>CBL系列云盒子</h2>
        <p>
          <a
            target="_blank"
            class="mr-20"
            href="https://oss.cnwscada.com/officalWebsite/assets/download/link/cbL2book.pdf"
            >1）CBL2系列双口云盒子产品手册</a
          >
          <a
            target="_blank"
            href="https://oss.cnwscada.com/officalWebsite/assets/download/link/cbl4book.pdf"
            >2）CBL4系列4口云盒子产品手册</a
          >
          ,如需下载，请进入“下载中心”。
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-12" id="cblproduct">
        <div
          class="block block-rounded block-bordered block-mode-loading-refresh"
        >
          <img
            src="https://oss.cnwscada.com/officalWebsite/assets/image/cblproduct.png"
            style="height: 100%; width: 100%"
          />
        </div>
      </div>
    </div>

    <div class="block block-rounded text-center" id="cblproductnew">
      <div class="block-content">
        <h2>2021年云盒子新品系列</h2>
        <p>
          <a
            target="_blank"
            class="mr-20"
            href="https://oss.cnwscada.com/officalWebsite/assets/download/link/cbl1book.pdf"
            >1）CBL1系列1口云盒子产品手册</a
          ><a
            target="_blank"
            class="mr-20"
            href="https://oss.cnwscada.com/officalWebsite/assets/download/link/cbl6book.pdf"
            >2）CBL6系列6口云盒子产品手册</a
          ><a
            target="_blank"
            href="https://oss.cnwscada.com/officalWebsite/assets/download/link/cblaibook.pdf"
            >3）CBLAI系列语音云盒子产品手册</a
          >,如需下载，请进入“下载中心”。
        </p>
      </div>
      <div class="row">
        <div class="col-md-12 col-12">
          <div
            class="
              block block-rounded block-bordered block-mode-loading-refresh
            "
          >
            <img
              src="https://oss.cnwscada.com/officalWebsite/assets/image/cblproductnew.png"
              style="height: 100%; width: 100%"
            />
          </div>
        </div>
      </div>
      <div class="block block-rounded text-center" id="gisproduct">
        <div class="block-content">
          <h2>电子地图GIS平台</h2>
          <p>
            <a
              target="_blank"
              href="https://oss.cnwscada.com/officalWebsite/assets/download/link/gisbook.pdf"
              >1）炫酷电子地图GIS平台解决方案</a
            >,如需下载，请进入“下载中心”。
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-12" id="gisproduct">
          <div
            class="
              block block-rounded block-bordered block-mode-loading-refresh
            "
          >
            <img
              src="https://oss.cnwscada.com/officalWebsite/assets/image/gisproduct.png"
              style="height: 100%; width: 100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
a {
  color: #98c593;
}

h2 {
  font-size: 2.25rem;
}

a:hover {
  cursor: pointer;
}

.nav-main-link-name {
  color: #000000;
}
.title {
  font-size: 28px;
  line-height: 120%;
  padding-bottom: 1.5rem;
  padding-top: 2.5rem;
}
.mr-20 {
  margin-right: 20px;
}
</style>
